<template>
  <a-card :bordered="true" class="header-solid h-full discussion" style="padding: 10px">
    <a-skeleton :loading="loading" active>
      <a-card-meta>
        <div slot="title">
          {{ discussionDetail.title }}
          <span style="margin-left: 10px">
          <a-dropdown>
            <a-icon type="more" :style="{'cursor':'pointer','font-size':'18px','font-weight':'bold'}"/>
            <a-menu slot="overlay" @click="">
              <a-menu-item key="1" @click="showTopicEditForm">
                Edit </a-menu-item>
              <a-menu-item key="2" @click="showDelete">
                Delete </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
          <span style="margin-left: 10px" v-if="discussionDetail.edited">
            <a-tooltip title="Edited">
                      <a-icon type="edit" :style="{'color': 'lightgrey','font-size':'18px'}"/>
              </a-tooltip>
        </span>
        </div>
      </a-card-meta>
      <div v-html="discussionDetail.description">
      </div>
    </a-skeleton>

    <div v-if="discussionDetail" style="display: flex;justify-content:space-between;margin-top: 10px;">
      <div style="margin-top: 10px; ">
        <a-icon type="message" style="margin-right: 8px"/>
        {{ discussionDetail.replies.length ? discussionDetail.replies.length : 'No' }} {{discussionDetail.replies.length==1?'Reply':'Replies'}}
      </div>
      <div class="table-avatar-info">
        <a-avatar style="margin-right: 10px" v-if="discussionDetail&&discussionDetail.user.imageUrl" slot="avatar"
                  :src="discussionDetail.user.imageUrl"/>
        <a-avatar v-else slot="avatar" style="background-color: #1890ff;margin-right: 10px">
          {{ (discussionDetail ? discussionDetail.user.name : '')|getNameForAvatar }}
        </a-avatar>
        <div class="avatar-info">
          <h6>{{ discussionDetail ? discussionDetail.user.name : '' }}</h6>
          <p style="font-size: 11px">{{ moment().diff(moment(discussionDetail.createdAt),'days')<7?(moment().diff(moment(discussionDetail.createdAt),'days')<1?(moment(discussionDetail.createdAt).fromNow()):(moment(discussionDetail.createdAt).fromNow()+' at '+moment(discussionDetail.createdAt).format('h:mm a'))):(moment(discussionDetail.createdAt).format('D MMM YYYY, hh:mm A')) }}</p>
        </div>
      </div>
    </div>
    <FormModal v-if="showModal" :visible="showModal" :selectedItem="selectedItem" :editMode="editMode"
               @formSuccess="formSuccess" @cancel="cancelForm"></FormModal>
    <ReplyEditModal :discussion_id="discussionDetail._id" v-if="showReplyModal" :visible="showReplyModal" :selectedItem="selectedReply"
               @formSuccess="replyFormSuccess" @cancel="cancelReplyForm"></ReplyEditModal>

    <a-divider style="margin: 25px 0 0 0;"></a-divider>

          <a-skeleton v-if="loading" :key="index" v-for="(item,index) in skeletonCount" style="margin-top: 25px"  :loading="loading" active avatar>

          </a-skeleton>

    <a-list v-if="listData.length" item-layout="vertical" size="large" :data-source="listData">
      <a-list-item slot="renderItem" key="item.title" slot-scope="item, index">
        <a-skeleton :loading="loading" active avatar>
          <a-list-item-meta :description="moment().diff(moment(item.createdAt),'days')<7?(moment().diff(moment(item.createdAt),'days')<1?(moment(item.createdAt).fromNow()):(moment(item.createdAt).fromNow()+' at '+moment(item.createdAt).format('h:mm a'))):(moment(item.createdAt).format('D MMM YYYY, hh:mm A'))">
            <div slot="title" href="javascript:;">{{ item.user.name }}
              <span style="margin-left: 10px">
            <a-dropdown>
              <a-icon type="more" :style="{'cursor':'pointer','font-size':'18px','font-weight':'bold'}"/>
             <a-menu slot="overlay" @click="">
              <a-menu-item key="1" @click="showReplyEditForm(item)">
                Edit </a-menu-item>
              <a-menu-item key="2" @click="showReplyDelete(item)">
                Delete </a-menu-item>
            </a-menu>
            </a-dropdown>


          </span>
              <span v-if="item.edited" style="margin-left: 10px">
            <a-tooltip placement="top">
                                  <template slot="title">
                                    Edited
                                  </template>
                      <a-icon type="edit" :style="{'font-size':'18px',
    'color': 'lightgrey'}"/>
              </a-tooltip>
        </span>

            </div>
            <a-avatar v-if="item.user.imageUrl" slot="avatar" :src="item.user.imageUrl"/>
            <a-avatar v-else slot="avatar" style="background-color: #1890ff">{{ item.user.name|getNameForAvatar }}
            </a-avatar>
          </a-list-item-meta>
          <div style="margin-left: 10px" v-html="item.detail"></div>
        </a-skeleton>
      </a-list-item>

    </a-list>
    <a-divider v-if="listData.length" style="margin: 5px 0 20px 0px"></a-divider>
    <a-comment v-if="!loading">
      <a-avatar slot="avatar" v-if="user&&user.image_url"
                :src="user.image_url" style="margin-right: 5px;"></a-avatar>
      <a-avatar slot="avatar" v-else
                :src="user.image_url" style="background-color: #1890ff;margin-right: 5px;">
        {{ (user ? user.name : 'User')|getNameForAvatar }}
      </a-avatar>
      <a-form layout='vertical' slot="content" @submit="handleSubmit">

        <a-form-item style="padding-bottom: 0;
    margin-bottom: 0;">
          <quill-editor
              id="editor"
              :options="editorOption"
              style="height: 260px; margin-bottom: 44px"
              v-model="reply"
          ></quill-editor>
          <!--              <Vueditor v-model="reply" style="height: 350px;"></Vueditor>-->

          <!--              <tinymce ref="tiny_mce" :other_options="{-->
          <!--         height: 300,-->
          <!--         }" id="d1" v-model="reply"></tinymce>-->
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 24 }" :style="{ textAlign: 'right' }" style="margin-top: 16px">
          <a-space size="small">
            <a-button :loading="confirmLoading" @click="handleSubmit">
              <a-icon type="form" theme="outlined"/>
              Reply to this conversation
            </a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </a-comment>

  </a-card>
</template>
<script>
import "quill/dist/quill.snow.css";
import moment from "moment";

import {mapGetters} from "vuex";

import {quillEditor, Quill} from "vue-quill-editor";
import FormModal from "./FormModal";
import ReplyEditModal from "./ReplyEditModal";


export default {
  data() {
    return {
      skeletonCount:[1,2,3,4,5],
      selectedItem: {},
      showModal: false,
      editMode: false,
      moment,
      confirmLoading: false,
      listData: [],
      reply: '',
      editorOption: {
        modules: {
          toolbar: [
            [{font: []}],
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            [
              {
                size: [
                  false,
                  "10px",
                  "12px",
                  "14px",
                  "16px",
                  "18px",
                  "20px",
                  "24px",
                  "30px",
                  "32px",
                  "36px",
                ],
              },
            ],
            [{color: []}],
            [{ background: [] }],

            ["bold", "italic", "underline", "strike", "link"],
            // Bold, italic, underline, strikethrough
            // Title, the form of key-value pairs; 1, 2 represents the font size
            [{list: "ordered"}, {list: "bullet"}],

            [{indent: "-1"}, {indent: "+1"}],
            // indent

            // font color, font background color
            // font
            [{align: []}],
            // alignment

            ["clean"],
            // Clear font style
          ],
        },
        placeholder: "Write a reply here",
      },
      discussionDetail: '',
      loading: false,
      showReplyModal:false,
      selectedReply:{}
    };
  },
  computed: {
    ...mapGetters(['user'])
  },
  components: {quillEditor, FormModal,ReplyEditModal},
  methods: {
    showDelete() {
      this.$confirm({
        title: 'Are you sure want to delete this discussion and related replies?',
        content: 'This process is irreversible. Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.loading = true;
          this.$store.dispatch("DELETE_FORUM", this.discussionDetail._id)
              .then((res) => {
                this.$message.success(res.data.message);
                this.$router.push({name:'Forum Feeds'})
              })
              .catch((err) => {
         //       this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.loading = false;
              });
        },
      });
    },
    showReplyDelete(item) {
      this.$confirm({
        title: 'Are you sure want to delete this reply?',
        content: 'This process is irreversible. Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.loading = true;
          this.$store.dispatch("DELETE_REPLY", {discussion_id: this.discussionDetail._id, id: item._id})
              .then((res) => {
                this.$message.success(res.data.message);
                this.discussionInfo()
              })
              .catch((err) => {
         //       this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.loading = false;
              });
        },
      });
    },
    formSuccess() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
      this.discussionInfo()
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
    },
    replyFormSuccess() {
      this.showReplyModal = false;
      this.selectedReply = {}
      this.discussionInfo()
    },
    cancelReplyForm() {
      this.showReplyModal = false;
      this.selectedReply = {}
    },
    handleSubmit(e) {
      e.preventDefault();

      // this.form.validateFields((err, values) => {
      //   if (!err) {
      if (!this.reply) {
        this.$message.error('Reply can not be empty');
        return
      }
      this.confirmLoading = true
      this.$store.dispatch("POST_REPLY", {reply: this.reply, id: this.$route.params.id})
          .then((res) => {
            this.$message.success(res.data.message);
            this.reply = ''
            this.discussionInfo()
          })
          .catch((err) => {
            // this.$message.error(err.response.data.message);
          })
          .finally(() => {
            this.confirmLoading = false
          });
      //   }
      // });
    },
    discussionInfo() {
      this.loading = true
      this.$store.dispatch("FETCH_FORUM", {id: this.$route.params.id})
          .then((res) => {

            this.discussionDetail = res.data

            this.listData = res.data.replies.sort(function(a,b){
              return new Date(a.createdAt)-new Date(b.createdAt) ;
            });

          }).finally(() => {
        this.loading = false
      })
    },
    showTopicEditForm(){
      this.editMode = true
      this.selectedItem = this.discussionDetail
      this.showModal = true;
    },
    showReplyEditForm(item){
      this.selectedReply = item
      this.showReplyModal = true;
    }
  },
  mounted() {
    this.discussionInfo()
  },
};
</script>
<style>
.discussion .ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0;
  margin-top: -2px;
}

.discussion .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 10px;
}

.discussion .ant-card-meta-title {
  font-weight: 600 !important;
  margin-bottom: 8px !important;
  font-size: 18px !important;
  overflow: unset;
  /*color: rgba(0, 0, 0, 0.85);*/
  white-space: unset;
  text-overflow: unset;
}

.discussion .ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.discussion .ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.discussion .ant-comment-content-author {
  display: none;
}

.discussion .ant-list-item-meta-title p {
  margin-bottom: 0 !important;
}

.discussion .ant-list-item p {
  margin-bottom: 0 !important;
}

.discussion .ant-list-item-meta-description {
  font-weight: 600 !important;
}

.discussion .ant-list-lg .ant-list-item {
  padding-top: 25px;
  padding-bottom: 25px;
}
</style>
