<template>
  <div>
    <a-modal
        :width="900"
        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-form-item label='Discussion Title'>
          <a-input
              v-decorator="[
              'title',
               { rules: [{ required: true, message: 'Title is required' }] ,initialValue:selectedItem.title,
              }
            ]"
          />
        </a-form-item>
        <a-form-item label='Description'>
          <quill-editor
              id="editor"
              :options="editorOption"
              style="height: 260px; margin-bottom: 44px"
              v-model="description"
          ></quill-editor>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import "quill/dist/quill.snow.css";

import { quillEditor, Quill } from "vue-quill-editor";

export default {
  props: ['editMode', 'visible','selectedItem'],
  data() {
    return {
      description: '',
      editorOption: {
        modules: {
          toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              {
                size: [
                  false,
                  "10px",
                  "12px",
                  "14px",
                  "16px",
                  "18px",
                  "20px",
                  "24px",
                  "30px",
                  "32px",
                  "36px",
                ],
              },
            ],
            [{ color: [] }],
            [{ background: [] }],

            ["bold", "italic", "underline", "strike", "link"],
            // Bold, italic, underline, strikethrough
            // Title, the form of key-value pairs; 1, 2 represents the font size
            [{ list: "ordered" }, { list: "bullet" }],

            [{ indent: "-1" }, { indent: "+1" }],
            // indent

            // font color, font background color
            // font
            [{ align: [] }],
            // alignment

            ["clean"],
            // Clear font style
          ],
        },
        placeholder: "Write a description here",
      },
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  components:{quillEditor},

  computed: {
    title() {
      return this.editMode ? 'Edit Discussion' : 'Create New Discussion'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      if (!this.description){
        this.$message.error('Description can not be empty');
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.id
          values.description=this.description
          this.confirmLoading = true
          if (this.editMode){
            this.$store.dispatch("UPDATE_FORUM", values)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_FORUM", values)
                .then((res) => {
                  // this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      if (this.fileList.length < 1)
        this.fileList = [...this.fileList, file];

      return false;
    },
    cancelForm(){
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
      if (this.editMode&&Object.keys(this.selectedItem).length!=0){
        this.id=this.selectedItem._id
     this.description=this.selectedItem.description
    }
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
</style>