<template>
  <div >
    <a-modal
        :width="900"
        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form class="reply-modal" layout='vertical' :form="form" @submit="handleOk">
        <a-form-item>
          <quill-editor
              id="editor"
              :options="editorOption"
              style="height: 260px; margin-bottom: 44px"
              v-model="description"
          ></quill-editor>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>

import "quill/dist/quill.snow.css";

import { quillEditor, Quill } from "vue-quill-editor";

export default {
  props: ['discussion_id', 'visible','selectedItem'],
  data() {
    return {
      description: '',
      editorOption: {
        modules: {
          toolbar: [
            [{ font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [
              {
                size: [
                  false,
                  "10px",
                  "12px",
                  "14px",
                  "16px",
                  "18px",
                  "20px",
                  "24px",
                  "30px",
                  "32px",
                  "36px",
                ],
              },
            ],
            [{ color: [] }],

            ["bold", "italic", "underline", "strike"],
            // Bold, italic, underline, strikethrough
            // Title, the form of key-value pairs; 1, 2 represents the font size
            [{ list: "ordered" }, { list: "bullet" }],

            [{ indent: "-1" }, { indent: "+1" }],
            // indent

            // font color, font background color
            // font
            [{ align: [] }],
            // alignment

            ["clean"],
            // Clear font style
          ],
        },
        placeholder: "Write a reply here",
      },
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:''

      // visible: false,
    };
  },
  components:{quillEditor},

  computed: {
    title() {
    return 'Edit Reply'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      if (!this.description){
        this.$message.error('Description can not be empty');
        return
      }
      // this.form.validateFields((err, values) => {
      //   if (!err) {
      let data={
        id:this.id,
        reply:this.description,
        discussion_id:this.discussion_id,
        createdAt:this.selectedItem.createdAt
      }
         this.confirmLoading = true
            this.$store.dispatch("UPDATE_REPLY", data)
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
        // }
      // });
    },
    cancelForm(){
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
  },
  mounted() {
        this.id=this.selectedItem._id
     this.description=this.selectedItem.detail
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
.reply-modal .ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.reply-modal .ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>